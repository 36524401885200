@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


$topBarHeight: 76px;

$heroSectionHeight: calc(100dvh - #{$topBarHeight});
.hero-section-height {
  height: $heroSectionHeight;
}

.top-bar-height {
  height: $topBarHeight;
}
